
/* .carousel-container {
  height: 122vh;  have kept hidden as not sure if necessary
} */


.carousel-image {
  object-fit: cover;
  max-height: 100%;
}

/* .carousel-caption{
  top: 0;
} */

.slogan{
  font-family: 'Croissant One', cursive;
  /* font-family: --croissant; */
  font-size: 3rem;
  z-index: 1;
  color: var(--off-white-color);
}

.dot{
  font-size: 2em;
}

.custom-opacity{
  opacity: 0.8;
}

.chevron-down{
  margin-top: 15rem;
  text-align: center;
  font-size: 4rem;
  z-index: 1;
  color: var(--licorice-black-color);
  opacity: 0.7;
  transition: 200ms ease-in-out;
}

.chevron-down:hover{
  opacity: 0.4;
}

.fade-in-text{
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
