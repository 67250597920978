
/* NAVBAR */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--nav-color);
  color: var(--off-white-color);
  font-family: text-me;
}

.brand-logo img {
  width: 150px;
  height: auto;
  margin: .5rem;
}

.navbar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li{
  list-style: none;
  padding: 1.8rem;
  font-size: 1.2em;
}

.navbar-links li a{
  text-decoration: none;
  color: #e9f0ec;
  display: block;
}

/* .navbar-links li .longer{
  margin-right: 0.5em;
} */

.navbar-links li:hover{
  background-color: rgba(75, 131, 181, 0.847);
  border-radius: 5px;
  font-weight: bolder;
}

.navlink-bold {
  font-weight: bolder;
  text-decoration: underline !important;
  text-underline-offset: .2em;
}
