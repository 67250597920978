
/* DEFINING COLOURS */

@font-face{
  font-family: text-me;
  src: url(../public/assets/TextMeOne-Regular.ttf);
}

/* @font-face{
  font-family: 'Croissant One';
  font-style: italic;
  font-weight: 100;
  src: url(../public/assets/CroissantOne-Regular.ttf);
} */

@font-face{
  font-family: josefin;
  src: url(../public/assets/JosefinSans-VariableFont_wght.ttf);
}

@font-face {
  font-family: ubuntu;
  src: url(../public/assets/Ubuntu-Regular.ttf);
}

:root {

  /* COLOURS */
  --nav-color: #5B94C7; /* navbar colour */
  --off-white-color: #EAE9EB; /* off-white colour */
  --licorice-black-color: #0D040F; /* black colour */
  --platinum-color: #EBEAE9; /* beige background colour */
  --grass-green-color: #ABBB5A; /* green colour */
  --yellow-color: #F1F3B4; /* yellow colour */
}

/* #ABBB5A green */

.wrapper{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
