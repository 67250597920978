#reviews{
  width: 100%;
  height: auto;
  padding: 3em 1em;
  color: var(--licorice-black-color);
  font-family: ubuntu;
  font-weight: 200;
}

.reviews-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.review-section{
  width: 100%;
  height: auto;
  text-align: center;
  padding: 1em 2em;
}

.review-txt{
  opacity: .8;
}
