#footer{
  align-items: center;
  justify-content: center;
  padding: 1em 1em;
  height: 100%;
  width: 100%;
  /* overflow-x: hidden; */
  /* background-color: #EBEAE9; */
  background-color: var(--nav-color);
  color: var(--off-white-color);
}

.footer-copyright{
  text-align: center;
  opacity: 0.9;
}

.footer-socials{
  text-align: center;
}

.footer-linkedin{
  font-size: 2em;
  color: var(--off-white-color);
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.footer-linkedin:hover{
  opacity: 0.8;
}

.footer-yt{
  margin-left: 8px;
  font-size: 2em;
  color: var(--off-white-color);
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.footer-yt:hover{
  opacity: 0.8;
}
