

#contact-form input{
  padding: 5px 5px;
  border-radius: 5px;
}

#contact-form input:not(.submit-btn){
  border: 1px solid rgb(0, 0, 0, 0.6);
}

#contact-form textarea{
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0, 0.6);
}

input:focus {
  outline: 2px solid var(--nav-color);
}

textarea:focus{
  outline: 2px solid var(--nav-color);
}

.submit-btn{
  background-color: var(--nav-color);
  color: var(--off-white-color);
  border: 1px solid var(--nav-color);
  font-weight: bold;
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.submit-btn:hover{
  opacity: 0.7;
}

/* CONDITIONAL  */

.max-chars{
  color: red;
}
