
#about{
  background: var(--platinum-color);
  width: 100%;
  /* height: 800px; */
  height: 100%;
  position: relative;
  /* overflow-x: hidden; */
}

.about-me{
  display: flex;
  padding: 4em;
  width: 135%;
}


.welcome-txt{
  font-size: 3em;
  color: #ABBB5A;
  text-align: center;
  /* font-family: var(--josefin); */
  font-family: josefin;
}

.welcome-content{
  color: var(--licorice-black-color);
  font-family: ubuntu;
  font-weight: 200;
  padding-right: 2em;
  opacity: 0.8;

}


.about-text{
  padding: 5.5em 0em;
  z-index: 1;
}


.about-bullets{
  list-style-type: '✧  ';
}

.about-collage{
  padding: 4em;
}

.about-collage-art{
  background-image: url(../../public/images/about-art.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 3em;
  width: 50%;
  height: 40%;
  position: relative;
}

.about-page-img{
  display: block;
  max-width: 60%;
  height: auto;
  border: 5px solid var(--grass-green-color);
}

.flower-border-top{
  position: absolute;
  top: 0;
  left: 0;
  width: 20em;
  z-index: 0;
}

.flower-border-bottom{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 23em;
  z-index: 0;
}



.services-link{
  /* text-align: center; */
  color: var(--grass-green-color);
  font-weight: 400;
  font-size: 1.2em;
  font-style: italic;
  font-family: ubuntu;
}

.services-hover:hover{
  color: #474e25;
}
