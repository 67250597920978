
#services{
  position: relative;
  width: 100%;
  /* background-color: #85a8b7; */
}

.services-slogan{
  font-size: 2.5em;
  font-weight: 300;
  text-align: center;
  font-style: italic;
  font-family: 'Croissant One';
  animation: fade-in ease-in 1s;
}

@keyframes fade-in{

  from{opacity: 0;}
  to{opacity: 1;}
}


.services-header{
  /* font-family: var(--josefin); */
  font-family: 'Croissant One';
}

.services-subheader{
  /* font-family: var(--josefin); */
  font-family: 'Croissant One';
}

.services-details{
  position: relative;
  padding: 5em 10em;
  /* background-color: #e6f0ee; */
  background: linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%);
  /* color: #85a8b7; */
  color: #4796b7;
  line-height: 2;
  font-family: ubuntu;
  font-weight: 200;
  /* border-bottom: 3px solid #85a8b7; */
}


.aims-list li{
  list-style: '→  ';
}


.how-it-works{
  /* background-color:#85a8b7; */
  background-color: var(--nav-color);
  width: 100%;
  padding: 2em 2em;
  font-family: ubuntu;
  font-weight: 200;
  color: var(--off-white-color);
}

.outcomes-list li{
  list-style: none;
  background-color: var(--nav-color);
  color: var(--off-white-color);
  border-radius: 8px;
  width: 15%;
  padding: .5em .5em;
  font-size: .8em;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center; /* display flex and align-items helps it sit in center */
}

.outcomes-list{
  margin-top: 2em;
  display: flex;
  gap: 1em;
}

.disclaimer-section{
  background-color: white;
  width: 100%;
  padding: 2em 2em;
  font-family: ubuntu;
  font-weight: 200;
  background: linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%);
}

.disclaimer{
  text-align: center;

}

.back-btn{
  position: absolute;
  top: 1em;
  left: 1em;
  opacity: 0.8;
  font-weight: bold;
  color: var(--licorice-black-color);
  transition: 200ms ease-in-out;
}

.back-btn:hover{
  opacity: 1;
}

.book{
  font-size: 1em;
  font-weight: bold;
  font-family: ubuntu;
  margin-top: 2em;
  /* padding: 1em; */
  /* text-align: center; */
  /* background-color: var(--nav-color);
  border: 2px solid var(--nav-color);
  border-radius: 5px; */
  /* width: 15%; */
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.book:hover{
  /* background-color: var(--off-white-color); */
  /* border: 2px solid var(--nav-color); */
  opacity: 0.7;
}

/* CONDITIONAL */

.click-btn{
  cursor: pointer;
  text-decoration: underline;
}

.works-hidden{
  display: none;
}

.services-circle-up{
  position: absolute;
  font-size: 3em;
  bottom: 0;
  right: .5em;
  color: var(--nav-color);
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.services-circle-up-2:hover{
  opacity: 0.7;
}

/* #BDB8B6 -services
#9a9593 - how it works */
