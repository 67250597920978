
@media (orientation: landscape) {
  .wrapper {
    margin: 0;
    overflow-x: hidden;
  }
}

@media (max-width: 960px){

  /* NAVBAR */

  .brand-logo img{
    width: 140px;
  }

  .navbar-links li{
    padding: 1rem;
    font-size: 1.2em;
  }


  /* BANNER */

  /* .carousel-container {
    height: 100vh;
 } */

  .slogan{
    font-size: 2.3em;

  }

  .chevron-down{
    font-size: 3.5em;
  }


  /* ABOUT  */

  #about{
    height: 100%;
  }

  .about-me{
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .flower-border-top{
    width: 20em;
  }

  .flower-border-bottom{
    width: 20em;
  }

  .about-page-img{
    margin-top: -3em;
    margin-left: 5em;
    max-width: 70%;
  }

  .about-collage{
    display: block;
    padding: 5em 5em;
  }

  /* SERVICES  */

  .outcomes-list li{
    width: 70%;
    margin-bottom: 2em;
  }

  .outcomes-list{
    display: block;
    margin-left: 5em;
  }

  .disclaimer p{
    padding: 1em 5em;
  }

  /* .outcomes-list li{
    width: 80%;
    margin-bottom: 2em;
  }

  .outcomes-list{
    display: block;
    margin-left: 2em;
  } */

    /* QUOTE STYLING */

    #quote{
      padding: 3em 3em;;
    }



  /* REVIEWS   */

  .reviews-wrapper{
    display: block;
  }

  /* CONTACT   */

  .contact-form-section{
    margin-left: 1em;
  }

  .contact-details{
    width: 100%;
    height: auto;
  }

  /* FOOTER  */

}

@media (max-width: 730px){

  /* NAVBAR */

  /* .brand-logo {
    width: 30%;
    margin: .2rem;
  } */

  .brand-logo img{
    width: 120px;
  }

  .navbar-links li{
    padding: 1rem;
    font-size: 1em;
  }

  .brand-logo{
    z-index: 3;
  }

  .navbar-links{
    display:none;
    width: 100%;
    opacity: 0; /* Start with opacity 0 */
    transition: opacity 0.5s ease-in-out;
    transform: translateX(-100%); /* Start with the menu above the viewport */
  }

  .navbar{
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links ul{
    width: 100%;
    flex-direction: column;
  }

  .navbar-links li{
    text-align: center;
    padding: 2rem;
    font-size: 1.5em;
  }

  .navbar-links li a {
    padding: .5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
    opacity: 1;
    transform: translateY(0); /* Move the menu to its normal position */
    z-index: 2;
    background-color: var(--nav-color); /* Apply the background color here */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }

  /* ANIMATED HAMBURGER MENU */

  .menu-btn{
    position: absolute;
    top: 1.8em;
    right: .9em;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .menu-btn-burger{
    width: 2em;
    height: .3em;
    background: white;
    border-radius: .5em;
    transition: all .5s ease-in-out;
    cursor: pointer;
  }


  .menu-btn-burger::before,
  .menu-btn-burger::after {
  content: '';
  position: absolute;
  width: 2em;
  height: .3em;
  background: white;
  border-radius: .5em;
  transition: all .5s ease-in-out;
  }

  .menu-btn-burger::before{
  transform: translateY(-0.7em);
  }

  .menu-btn-burger::after{
  transform: translateY(0.7em);
  }

  /* ANIMATION HERE */

  .menu-btn.exit-nav .menu-btn-burger{
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
  }

  .menu-btn.exit-nav .menu-btn-burger::before{
  transform: rotate(45deg) translate(35px, -35px);
  }

  .menu-btn.exit-nav .menu-btn-burger::after{
  transform: rotate(-45deg) translate(35px, 35px);
  }


  /* BANNER */

  /* .carousel-container {
    height: 100vh;
 } */

  .slogan{
    font-size: 2.5rem;
  }

  .chevron-down{
    font-size: 3rem;
  }


  /* ABOUT  */

  #about{
    height: 100%;
  }

  .about-me{
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .flower-border-top{
    width: 15em;
  }

  .flower-border-bottom{
    width: 15em;
  }

  .about-page-img{
    margin-top: -3em;
  }

  .about-collage{
    display: block;
    padding: 5em 5em;
  }


  /* SERVICES  */

  #services{
    height: 100%;
  }

  .services-details{
    padding: 2em 2em;
  }

  .services-slogan{
    font-size: 2em;
    margin-top: 2em;
  }

  .outcomes-list li{
    width: 80%;
    margin-bottom: 2em;
  }

  .outcomes-list{
    display: block;
  }

  .services-circle-up{
    right: .1em;
  }

   /* QUOTE STYLING */

   #quote{
    padding: 2em 2em;;
  }

  #quote .quote-text{
    font-size: 1.5em;
  }


  /* REVIEWS   */

  .reviews-wrapper{
    display: block;
  }

  /* CONTACT   */

  #contact{
    height: 100%;
  }

  .contact-header{
    margin: .5em;
  }

  .contact-wrapper{
    display: block;
    flex-direction: column; /* Change to column to stack elements vertically */
    align-items: center; /* Center horizontally */
    text-align: center;
  }

  .contact-details{
    display: block;
    margin-bottom: 3em;
  }

  .discussion-list{
    text-align: start;
    margin-left: 2em;
  }

  .contact-form-section{
    display: block;
    margin: 0 auto;
    text-align: left;
    margin-left: -2em;
  }

  .submit-btn{
    width: 91% !important;
  }

  .contact-circle-up{
    right: .2em;
  }

  /* FOOTER  */

}

@media (max-width: 540px){

  .wrapper{
    width: 100%;
  }

  /* NAVBAR STYLING */

  .brand-logo img{
    width: 100px;
  }

  .navbar-links{
    display:none;
    width: 100%;
    opacity: 0; /* Start with opacity 0 */
    transition: opacity 0.5s ease-in-out;
    transform: translateX(-100%); /* Start with the menu above the viewport */
  }

  .navbar{
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links ul{
    width: 100%;
    flex-direction: column;
  }

  .navbar-links li{
    text-align: center;
    padding: 2rem;
    font-size: 1.5em;
  }

  .navbar-links li a {
    padding: .5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
    opacity: 1;
    transform: translateY(0); /* Move the menu to its normal position */
    z-index: 2;
    background-color: var(--nav-color); /* Apply the background color here */
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 10em; /* maybe change to 10em? */
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    animation: fadeIn .4s ease-in;
  }

  @keyframes fadeIn {
    from{opacity: 0;}
    to{opacity: 1;}
  }

  /* ANIMATED HAMBURGER MENU */

  .menu-btn{
    position: absolute;
    top: 1.8em;
    right: .9em;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .menu-btn-burger{
    width: 2em;
    height: .3em;
    background: white;
    border-radius: .5em;
    transition: all .5s ease-in-out;
    cursor: pointer;
  }


  .menu-btn-burger::before,
  .menu-btn-burger::after {
  content: '';
  position: absolute;
  width: 2em;
  height: .3em;
  background: white;
  border-radius: .5em;
  transition: all .5s ease-in-out;
  }

  .menu-btn-burger::before{
  transform: translateY(-0.7em);
  }

  .menu-btn-burger::after{
  transform: translateY(0.7em);
  }

  /* ANIMATION HERE */

  .menu-btn.exit-nav .menu-btn-burger{
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
  }

  .menu-btn.exit-nav .menu-btn-burger::before{
  transform: rotate(45deg) translate(35px, -35px);
  }

  .menu-btn.exit-nav .menu-btn-burger::after{
  transform: rotate(-45deg) translate(35px, 35px);
  }

  /* BANNER STYLING */

  /* .carousel-container {
    height: 100vh;
 } */

  .slogan{
    font-size: 2rem;
  }


  /* ABOUT STYLING */

  #about{
    height: 100%;
  }

  .about-me{
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .about-text{
    padding: 0em;
  }

  .flower-border-top{
    width: 10em;
  }

  .flower-border-bottom{
    width: 10em;
  }

  .welcome-txt{
    font-size: 1.9em;
    margin-top: 1em;
  }

  .welcome-content{
    text-align: start;
    padding: 1em 0em;
  }

  .about-collage{
    display: initial;
  }

  .about-page-img{
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
  }

  .about-collage-art{
    display: none;
  }

  .services-link{
    font-size: 1.5em;
    text-align: center;
  }



  /* SERVICES STYLING */

  #services{
    /* padding: 3em 1em; */
    height: 100%;
  }

  .services-details{
    padding: 2em 2em;
  }

  .services-slogan{
    font-size: 1.5em;
    margin-top: 2em;
  }

  .outcomes-list li{
    width: 80%;
    margin-bottom: 2em;
  }

  .outcomes-list{
    display: block;
    margin-left: 2em;
  }

  .services-circle-up{
    right: .1em;
  }

  /* QUOTE STYLING */

  #quote{
    padding: 2em 2em;;
  }

  #quote .quote-text{
    font-size: 1em;
  }


  /* REVIEWS STYLING */

  .reviews-wrapper{
    display: block;
  }


  /* CONTACT STYLING */

  #contact{
    height: 100%;
  }

  .contact-header{
    margin: .5em;
  }

  .contact-wrapper{
    display: block;
    flex-direction: column; /* Change to column to stack elements vertically */
    align-items: center; /* Center horizontally */
    text-align: center;
  }

  .contact-details{
    display: block;
    margin-bottom: 3em;
  }

  .discussion-list{
    text-align: start;
    margin-left: 2em;
  }

  .contact-form-section{
    display: block;
    margin: 0 auto;
    text-align: left;
    margin-left: -2em;
  }

  .submit-btn{
    width: 91% !important;
  }

  .contact-circle-up{
    right: .2em;
  }

}
