
#contact{
  position: relative;
  width: 100%;
  height: auto;
  /* background-color: #e3edef; */
  /* background: linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%); */
  background: linear-gradient(108.1deg, rgb(167, 220, 225) 11.2%, rgb(217, 239, 242) 88.9%);
  color: var(--licorice-black-color);
  padding: 3em 3em;
  font-family: ubuntu;
  font-weight: 200;
}

.contact-details{
  line-height: 2em;
}

.contact-header{
  font-size: 3em;
  font-family: josefin;
}

.discussion-list li{
  list-style-type: '✧  ';
  font-size: small;
}

.youtube{
  font-size: 1.5em;
}


.contact-wrapper{
  display: flex;
}


.contact-form-section{
  padding-left: 4em;
}


.contact-circle-up{
  position: absolute;
  font-size: 3em;
  bottom: .5em;
  right: .5em;
  color: var(--nav-color);
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.contact-circle-up:hover{
  opacity: 0.7;
}


/* STICKY */

/* .chevron-btn-container{
  padding-top: 3em;
} */

/* .chevron-btn{
  float: right;
  margin-right: 15px;
  margin-top: 1em;
  font-size: 2em;
  border-radius: 50%;
  padding: 0px 8px;
  cursor: pointer;
  border: 1px solid var(--nav-color);
  background-color: var(--nav-color);
  color: var(--off-white-color);
  transition: 200ms ease-in-out;
}

.chevron-btn:hover{
  opacity: 0.8;
}

.sticky{
  position: sticky;
  top: 20px;
} */
